import { graphqlBaseUrlTest, graphqlBaseUrlLive } from "helpers";

export const fetchBaseURL = (ID = "", wordToReplace = "") => {
    const mode = ID.replace(wordToReplace, "").substring(0, 4);
    if (mode === "test") {
        return graphqlBaseUrlTest;
    } else if (mode === "live") {
        return graphqlBaseUrlLive;
    } else {
        return graphqlBaseUrlTest;
    }
};
