/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { toast } from "react-toastify";

import { apiCall } from "store/actions";
import { CHECK_FOR_PLINK_TXNS } from "graphql";
import { getBlockchainCurrency } from "utils";

import Loader from "components/Loader";

export default ({ paymentDetails, handlePage }) => {
    const [minutes, setMinutes] = useState(9);
    const [seconds, setSeconds] = useState(59);
    const [apiCallCount, setApiCallCount] = useState(0);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (!minutes && !seconds) return;
        const intervalId = setInterval(() => {
            if (seconds) {
                setSeconds(seconds - 1);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [seconds]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiCall(paymentDetails.baseUrl, CHECK_FOR_PLINK_TXNS, {
                plinkId: paymentDetails.plinkId,
            });
            setApiCallCount(apiCallCount + 1);

            if (!response.status) {
                return;
            }

            const {
                check_for_plink_txns: { status, message },
            } = response.data;

            if (status) {
                // toast.success(message ?? "Transaction was successful");
                return handlePage(4);
            }
        };

        const timer = setTimeout(() => {
            fetchData();
        }, 15000);

        return () => clearTimeout(timer);
    }, [apiCallCount]);

    return (
        <>
            <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm">
                        You need to carry out a transaction of{" "}
                        <strong style={{ color: "green", textTransform: "uppercase" }}>
                            {paymentDetails.amount}{" "}
                            {paymentDetails.network
                                ? getBlockchainCurrency(paymentDetails.network)
                                : paymentDetails.currency}
                        </strong>{" "}
                        to the wallet address
                    </h6>
                    <div className="id-view text-blueGray-500 text-sm">
                        {/* <div className="title font-bold">wallet address</div>  */}
                        <div className="id">{paymentDetails.walletAddress}</div>
                        <CopyToClipboard text={paymentDetails.walletAddress} onCopy={() => setCopied(true)}>
                            <button style={{ backgroundColor: !copied ? "rgb(0, 81, 255)" : "#6BC654" }}>
                                {!copied ? "Copy" : "copied"}
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                {!minutes && !seconds ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <strong>Transaction Timeout</strong>
                    </div>
                ) : (
                    <>
                        <Loader type="watch" />
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <strong>
                                0{minutes}:{seconds >= 10 ? seconds : `0${seconds}`}
                            </strong>
                        </div>
                        <br />
                        <div className="px-4 py-0">
                            <ul style={{ listStyleType: "circle", fontSize: "small" }}>
                                <li>You have 10 minutes to complete this transaction.</li>
                                <li>You will receive an email once you complete the transaction.</li>
                                <li>Do not close or refresh this window</li>
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
