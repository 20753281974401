import { post } from "services";

export const apiCall = async (path, query, variables = {}) => {
    try {
        let apiResponse = await post(path, {
            query: query,
            variables: variables,
        });

        if (apiResponse.data.customStatus) {
            return {
                status: true,
                data: apiResponse.data.data,
            };
        }

        return { status: false, data: apiResponse.data.errors ? apiResponse.data.errors[0] : {} };
    } catch (err) {
        return { status: false, data: {} };
    }
};
