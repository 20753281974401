export const CREATE_NEW_ORDER = `mutation ($email: String!, $merchantId: String!, $amount: Float!, $network: String) {
  create_new_order(email: $email, merchant_id: $merchantId, amount: $amount, network: $network) {
    message
    order_id
    wallet_address
  }
}

  `;

export const CHECK_FOR_ORDER_TXN = `query ($orderId: String!) {
  check_for_order_txns(order_id: $orderId) {
    status
    message
  }
}
`;

export const MAKE_ORDER_TXN = `mutation ($token: String!) {
  create_order_transaction(token: $token) {
    message
  }
}
`;

export const FETCH_ORDER_DETAILS = `query ($orderId: String!) {
  list_order_details(order_id: $orderId) {
    message
    data {
      _id
      order_id
      amount
      currency
      created_at
      status
      merchant_id {
        name
        email
      }
    }
  }
}
`;

export const UPDATE_ORDER_DETAILS = `mutation ($email: String!, $orderId: String!, $amount: Float, $network: String) {
  update_order_details(email: $email, order_id: $orderId, amount: $amount, network: $network) {
    message
    wallet_address
  }
}
`;
