/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

//components
import { Modal } from "@mui/material";
import FirstPage from "./first-page";
import SecondPage from "./countdown";
import ErrorPage from "../common/errorPage";
import SuccessPage from "../common/successPage";

import { apiCall, toggleLoading } from "store/actions";
import { FETCH_PAGE_DETAILS } from "graphql";
import { fetchBaseURL } from "utils";
import OpeningPage from "./openingPage";

const projectName = process.env.REACT_APP_PROJECT_NAME;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    outline: "none",
    boxShadow: 24,
    p: 4,
};

export default function ({ loading }) {
    const dispatch = useDispatch();
    const { pageId } = useParams();

    const [page, setPage] = useState(0);
    const [error, setError] = useState("");
    const [details, setDetails] = useState({
        pageId: pageId,
        title: "",
        walletAddress: "",
        paymentId: "",
        amount: 0,
        amountType: "",
        merchant: "",
        currency: "",
        network: "",
        contactEmail: "",
        paymentId: "",
        extraFields: [],
        baseUrl: fetchBaseURL(pageId, "pg_"),
    });

    useEffect(() => {
        fetchPageDetails();
    }, []);

    const fetchPageDetails = async () => {
        dispatch(toggleLoading(true));
        const response = await apiCall(details.baseUrl, FETCH_PAGE_DETAILS, { pageId: pageId });

        if (!response.status) {
            dispatch(toggleLoading(false));
            setError(response.data?.message ?? "Payment Page not working");
            return setPage(3);
        }

        const {
            data: { title, amount, amount_type, currency, contact_us_email, chain_network, merchant_id, fields },
        } = response.data?.list_payment_page_details;

        setDetails({
            ...details,
            title: title,
            merchant: merchant_id?.name ?? "",
            amount: amount,
            network: chain_network,
            currency: currency,
            amountType: amount_type,
            contactEmail: contact_us_email,
            extraFields: fields,
        });
        document.title = `${title} | ${projectName}`;
        dispatch(toggleLoading(false));
        return handlePage(1);
    };

    const handlePage = (data) => {
        setPage(data);
    };

    const handlePaymentDetails = (data) => {
        setDetails(data);
    };

    return (
        <>
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-4/12 px-4">
                        <Modal open={!loading}>
                            <div
                                style={style}
                                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
                            >
                                {page === 1 ? (
                                    <OpeningPage handlePage={handlePage} paymentDetails={details}/>
                                ) : page === 2 ? (
                                    <FirstPage
                                        dispatch={dispatch}
                                        handlePage={handlePage}
                                        handlePaymentDetails={handlePaymentDetails}
                                        paymentDetails={details}
                                    />
                                ) : page === 3 ? (
                                    <SecondPage handlePage={handlePage} paymentDetails={details} />
                                ) : page === 4 ? (
                                    <ErrorPage error={error} />
                                ) : page === 5 ? (
                                    <SuccessPage />
                                ) : null}
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
}
