import React from "react";
import { Email } from "@mui/icons-material";
import { Box, Button, Card, Stack, Typography } from "@mui/material";

export default function OpeningPage({handlePage, paymentDetails}) {
  return (
    <>
      <Card sx={{ borderRadius: "10px" }}>
        <Box sx={{ p: 3, width: "100%" }}>
          <Stack spacing={1}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 3 }} fontSize={"large"}>
              {paymentDetails.merchant}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              <b style={{ borderBottom: "5px solid #ccc" }}>{paymentDetails.title}</b>
            </Typography>

            {paymentDetails?.extraFields?.map((e, i) => (
              <Stack key={i}>
                {e.value ? (
                  <>
                    <Typography sx={{ fontWeight: "bold" }}>{e.name}</Typography>
                    <Typography variant="body2" sx={{ color: "#696969" }}>
                      {e.value}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Stack>
            ))}

            <Stack>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Contact us
              </Typography>
              <Typography variant="body1" sx={{ color: "#696969" }}>
                <Email fontSize="small" /> {paymentDetails.contactEmail}
              </Typography>
            </Stack>
            <Button variant="contained" onClick={() => handlePage(2)}>
                <b>Next</b>
            </Button>
          </Stack>
        </Box>
      </Card>
    </>
  );
}
