import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store/store";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "react-toastify/dist/ReactToastify.css";

// layouts
import Auth from "layouts/Auth.js";

ReactDOM.render(
    <>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

                <BrowserRouter>
                    <Switch>
                        <Route path="/" component={Auth} />
                        {/* add redirect for first page */}
                        <Redirect from="*" to="/" />
                    </Switch>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </>,
    document.getElementById("root")
);
