import CryptoConvert from "crypto-convert";
const convert = new CryptoConvert();

export async function cryptoConverter(from = "SOL", to = "ethereum", value = 0) {
    let result = (async function () {
        if (value === 0) {
            return 0;
        }
        from = from.toUpperCase();
        await convert.ready();

        if (to === "solana") {
            to = "SOL";
        } else if (to === "ethereum") {
            to = "ETH";
        } else if (to === "avalanche") {
            to = "AVAX";
        } else if (to === "polygon") {
            to = "MATIC";
        }
        let converted = convert[from][to](value);
        
        if(Number.isSafeInteger(converted)) {
            return converted;
        } else {
            return converted.toFixed(5);
        }
    })();
    return result;
}
