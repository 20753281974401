/*eslint-disable*/
import React from "react";
import ErrorIcon from "@mui/icons-material/Error";

export default ({ error = "Something went wrong" }) => {
    return (
        <>
            <div className="rounded-t mb-0 px-6 py-6">
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <ErrorIcon color="error" fontSize="large" />
                    </div>
                    <h3 className="text-blueGray-500 font-bold text-center mb-3">
                        <strong>{error}</strong>
                    </h3>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
            </div>
        </>
    );
};
