import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// components
import FooterSmall from "components/Footers/FooterSmall.js";

// import Login from "views/auth/Login.js";
// import PaymentConfirmation from "views/payment/confirmation";
// import PaymentLinkConfirmation from "views/payment-link/confirmation";
import Loader from "components/Loader";
import Payments from "views/payment";
import PaymentLink from "views/payment-link";
import Orders from "views/orders";
import PaymentPage from "views/payment-page";

import { history } from "utils";

export default function Auth() {
    const { loading } = useSelector(({ loading }) => ({
        loading,
    }));

    return (
        <>
            <main>
                <section className="relative w-full h-full py-40 min-h-screen">
                    <div className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"></div>
                    <Loader open={loading} />
                    <Router history={history}>
                        <Switch>
                            <Route path="/" exact render={(props) => <Payments {...props} loading={loading} />} />
                            {/* <Route path="/confirm/:token" exact component={PaymentConfirmation} /> */}
                            <Route
                                path="/plink/:plinkId"
                                exact
                                render={(props) => <PaymentLink {...props} loading={loading} />}
                            />

                            <Route
                                path="/order/:orderId"
                                exact
                                render={(props) => <Orders {...props} loading={loading} />}
                            />

                            <Route
                                path="/page/:pageId"
                                exact
                                render={(props) => <PaymentPage {...props} loading={loading} />}
                            />
                            {/* <Route path="/plink/confirm/:token" exact component={PaymentLinkConfirmation} /> */}
                            <Redirect from="/" to="/" />
                        </Switch>
                    </Router>
                    <FooterSmall absolute />
                </section>
            </main>
        </>
    );
}
