/*eslint-disable*/
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default ({ message = "Payment was successful" }) => {
    return (
        <>
            <div className="rounded-t mb-0 px-6 py-6">
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CheckCircleIcon color="success" fontSize="large" />
                    </div>
                    <h3 className="text-blueGray-500 font-bold text-center mb-3">
                        <strong>{message}</strong>
                    </h3>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
            </div>
        </>
    );
};
