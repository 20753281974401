/*eslint-disable*/
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";

import { apiCall, toggleLoading } from "store/actions";
import { CREATE_NEW_ORDER } from "graphql";

export default ({ handlePage, handlePaymentDetails, paymentDetails }) => {
    const dispatch = useDispatch();

    const handleContinue = async (data) => {
        dispatch(toggleLoading(true));
        data.merchantId = "6346c261e290c8c2adfa9fe6";

        let network = "solana";
        if (data.currency.length) {
            network = data.currency[0].value;
        }
        data.network = network;

        const response = await apiCall(paymentDetails.baseUrl, CREATE_NEW_ORDER, data);
        if (!response.status) {
            toast.error("Failed to initiate order");
            dispatch(toggleLoading(false));
            return;
        }
        const { order_id, wallet_address } = response.data?.create_new_order;

        handlePaymentDetails({ orderId: order_id, walletAddress: wallet_address, amount: data.amount, network: network });
        dispatch(toggleLoading(false));
        return handlePage(2);
    };

    return (
        <>
            <div className="rounded-t mb-0 px-6 py-6">
                {/* <div className="close">
                    <i className="fa fa-close" onClick={handleClose}/>
                </div> */}
                <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">Payment to merchant</h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <Formik
                    initialValues={{
                        email: "",
                        currency: [{ value: "solana", label: "Solana" }],
                        amount: 0.02,
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                    })}
                    onSubmit={(value) => handleContinue(value)}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                        <form style={{ paddingTop: "2em" }} onSubmit={handleSubmit}>
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {Boolean(touched.email && errors.email) ? (
                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                        {errors.email}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Currency
                                </label>
                                <Select
                                    key="currency"
                                    name="currency"
                                    defaultValue={[{ value: "solana", label: "Solana" }]}
                                    options={[
                                        { value: "solana", label: "Solana" },
                                        { value: "ethereum", label: "Ethereum (ethereum chain)" },
                                        { value: "avalanche", label: "Avalanche (ethereum chain)" },
                                        { value: "polygon", label: "Polygon Matic (ethereum chain)" },
                                    ]}
                                    isOptionDisabled={(option) => option.disabled}
                                    isClearable={false}
                                    value={values.currency}
                                    onChange={(selectedOption) => {
                                        let event = { target: { name: "currency", value: [selectedOption] } };
                                        handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                />
                            </div>

                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Amount
                                </label>
                                <input
                                    type="number"
                                    name="amount"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="amount"
                                    value={values.amount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    min={0.02}
                                />
                            </div>

                            <div className="text-center mt-6">
                                <button
                                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={values.email === ""}
                                >
                                    Continue
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );
};
