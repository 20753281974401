export const FETCH_PAGE_DETAILS = `query ($pageId: String!) {
    list_payment_page_details(page_id: $pageId) {
      data {
        _id
        page_id
        amount
        amount_type
        currency
        chain_network
        status
        title
        contact_us_email
        fields {
          name
          value
        }
        merchant_id {
          name
          email
        }
      }
    }
  }
  
  `;

export const CHECK_FOR_PAGE_TXNS = `query ($paymentId: String!) {
    check_for_page_txns(payment_id: $paymentId) {
      status
      message
    }
  }
  `;

export const MAKE_PAGE_PAYMENT = `mutation ($pageId: String!, $email: String!, $network: String, $amount: Float) {
    make_page_payment(page_id: $pageId, email: $email, network: $network, amount: $amount) {
      wallet_address
      payment_id
    }
  }
  `;
