/*eslint-disable*/
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import Select from "react-select";

import { apiCall, toggleLoading } from "store/actions";
import { MAKE_PAGE_PAYMENT } from "graphql";
import { cryptoConverter, getBlockchainNetwork, getCurrencyName, getBlockchainCurrency } from "utils";
import { Box, Button, Card, InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";
import { Email, Phone } from "@mui/icons-material";

export default ({ dispatch, handlePage, handlePaymentDetails, paymentDetails }) => {
    const [amount, setAmount] = useState(0.02);
    const [convertTo, setConvertTo] = useState(getCurrencyName(paymentDetails.currency));
    const [isAmountFixed, setIsAmountFixed] = useState(false);

    const handleContinue = async (data) => {
        dispatch(toggleLoading(true));
        data.pageId = paymentDetails.pageId;

        let network = "solana";
        if (data.currency.length) {
            network = data.currency[0].value;
        }
        data.network = network;
        data.amount = amount;

        const response = await apiCall(paymentDetails.baseUrl, MAKE_PAGE_PAYMENT, data);
        if (!response.status) {
            toast.error("Failed to initiate transaction");
            return dispatch(toggleLoading(false));
        }
        const { wallet_address, payment_id } = response.data?.make_page_payment;

        handlePaymentDetails({
            ...paymentDetails,
            walletAddress: wallet_address,
            paymentId: payment_id,
            network: network,
            amount: amount,
        });
        dispatch(toggleLoading(false));
        return handlePage(3);
    };

    useEffect(async () => {
        if (paymentDetails.amountType === "fixed") {
            setIsAmountFixed(true);
            setAmount(await cryptoConverter(paymentDetails.currency, convertTo, paymentDetails.amount));
        }
    }, [convertTo]);

    const generateOptions = (value = "") => {
        let options = [
            { value: "solana", label: "Solana" },
            { value: "ethereum", label: "Ethereum (ethereum chain)" },
            { value: "avalanche", label: "Avalanche (ethereum chain)" },
            { value: "polygon", label: "Polygon Matic (ethereum chain)" },
        ];
        if (!value) {
            return options;
        } else {
            return options.filter((e) => e.value === value);
        }
    };

    return (
        <>
            <Card sx={{ borderRadius: "10px" }}>
                <Box sx={{ p: 3, width: "100%" }}>
                    <Stack spacing={2} sx={{ mb: 3 }}>
                        <Typography className="text-blueGray-600 text-sm text-center" variant='sutitle2'>
                            Payment Request from <strong>{paymentDetails?.merchant}</strong>
                            <hr className="mt-3 border-b-1 border-blueGray-300" />
                        </Typography>
                        <div style={{ padding: 10, borderRadius: "10px", backgroundColor: "#f0f0f0" }} className="amount">
                            <div style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }} className="box">
                                <img src={getBlockchainNetwork(convertTo)} />
                            </div>
                            <div className="content">
                                <h3>Amount payable:</h3>
                                <b style={{ textTransform: "uppercase" }}>
                                    {/* {paymentDetails.amount} {paymentDetails.currency}{" "} */}
                                    {amount} {getBlockchainCurrency(convertTo)}
                                </b>
                                <br />
                            </div>
                        </div>
                    </Stack>
                    <div>
                        <Formik
                            initialValues={{
                                email: "",
                                phone: "",
                                currency: generateOptions(getCurrencyName(paymentDetails.currency)),
                                // amount: isAmountFixed ? 0 : 0.02,
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                            })}
                            onSubmit={(value) => {
                                return new Promise((resolve, reject) => {
                                    handleContinue(value).then(() => {
                                        resolve({});
                                    });
                                });
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form onSubmit={handleSubmit}>

                                    <Stack spacing={2}>
                                        <div className="relative w-full">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Currency
                                            </label>
                                            <Select
                                                key="currency"
                                                name="currency"
                                                defaultValue={generateOptions(getCurrencyName(paymentDetails.currency))}
                                                options={generateOptions()}
                                                isOptionDisabled={(option) => option.disabled}
                                                isClearable={false}
                                                value={values.currency}
                                                onChange={(selectedOption) => {
                                                    let event = { target: { name: "currency", value: [selectedOption] } };
                                                    handleChange(event);
                                                    setConvertTo(selectedOption.value);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                        </div>

                                        {!isAmountFixed ? (
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Amount
                                                </label>
                                                <OutlinedInput
                                                    fullWidth
                                                    size="small"
                                                    type="number"
                                                    // name="amount"
                                                    placeholder="amount"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    onBlur={handleBlur}
                                                    inputProps={{ min: 0.02 }}
                                                />
                                            </div>
                                        ) : null}

                                        <Stack direction='row' spacing={1} alignItems='center'>
                                            <label style={{ width: '50%' }} className="uppercase text-blueGray-600 text-xs font-bold">
                                                Email
                                            </label>
                                            <Stack sx={{ width: "100%" }}>
                                                <OutlinedInput
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <Email fontSize="12px" />
                                                        </InputAdornment>
                                                    }
                                                />
                                                {Boolean(touched.email && errors.email) ? (
                                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                                        {errors.email}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </Stack>
                                        </Stack>
                                        <Stack direction='row' spacing={1} alignItems='center'>
                                            <label style={{ width: '50%' }} className="uppercase text-blueGray-600 text-xs font-bold">
                                                Phone
                                            </label>
                                            <Stack sx={{ width: "100%" }}>
                                                <OutlinedInput
                                                    fullWidth
                                                    size="small"
                                                    placeholder="Enter phone"
                                                    type="number"
                                                    name="phone"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <Phone fontSize="12px" />
                                                        </InputAdornment>
                                                    }
                                                />
                                                {Boolean(touched.phone && errors.phone) ? (
                                                    <div style={{ display: "block", marginLeft: "10px", color: "red", fontSize: 12 }}>
                                                        {errors.phone}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </Stack>
                                        </Stack>

                                        <Button
                                            fullWidth
                                            variant="contained"
                                            type="submit"
                                            onClick={handleSubmit}
                                            disabled={values.email === "" || isAmountFixed ? amount <= 0.01 : false}
                                        >
                                            <b>Pay {amount} {getBlockchainCurrency(convertTo)}</b>
                                        </Button>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            onClick={() => handlePage(1)}
                                        >
                                            <small><b>Go back</b></small>
                                        </Button>
                                    </Stack>
                                </form>
                            )}
                        </Formik>
                    </div>
                </Box>
            </Card>
        </>
    );
};
