import { combineReducers } from "redux";
import loadingReducer from "./loadingReducer";
import { LOGOUT } from "../types";

const appReducer = combineReducers({
    loading: loadingReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
